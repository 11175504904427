import { StudentProfileProvider, ApiWrapper, LocationValidator, InactivityTimeout } from '@papa-internal/components';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { theme } from './styles/theme';
import { QueryClient } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { FormLayout, StyledBottomNav, StyledPrevious, StyledSave, StyledSubmit } from './Pages/Form/FormLayout';
import { StudentAnswersKeys } from './dataModel/types';
import { schema } from './dataModel/yupSchema';

const queryClient = new QueryClient();

// Form Pages
export const loadAboutYou = () => import('./Pages/Form/AboutYou');
export const loadEducation = () => import('./Pages/Form/Education');
export const loadFinalSteps = () => import('./Pages/Form/FinalSteps');
export const loadCompletion = () => import('./Pages/Form/Completion');
const AboutYou = lazy(loadAboutYou);
const Education = lazy(loadEducation);
const FinalSteps = lazy(loadFinalSteps);
const Completion = lazy(loadCompletion);

// Account Pages
export const loadSignUp = () => import('./Pages/Account/SignUp');
export const loadConfirm = () => import('./Pages/Account/Confirm');
export const loadLogin = () => import('./Pages/Account/Login');
export const loadForgot = () => import('./Pages/Account/ForgotPassword');
export const loadReset = () => import('./Pages/Account/ResetPassword');
const SignUp = lazy(loadSignUp);
const Confirm = lazy(loadConfirm);
const Login = lazy(loadLogin);
const ForgotPassword = lazy(loadForgot);
const ResetPassword = lazy(loadReset);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ApiWrapper.Root queryClient={queryClient}>
                    <StudentProfileProvider queryClient={queryClient}>
                        <InactivityTimeout.Root queryClient={queryClient} />
                        <LocationValidator.Root />
                        <Suspense>
                            <Routes>
                                <Route // FORM PAGES
                                    path="/about"
                                    element={
                                        <FormLayout
                                            queryClient={queryClient}
                                            formPage={<AboutYou />}
                                            bottomNav={
                                                <StyledBottomNav aria-label="Save and Continue" justify-content="flex-end">
                                                    <StyledSave targetRoute="/education" buttonText="Save and Continue" />
                                                </StyledBottomNav>
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/education"
                                    element={
                                        <FormLayout
                                            queryClient={queryClient}
                                            formPage={<Education queryClient={queryClient} />}
                                            bottomNav={
                                                <StyledBottomNav aria-label="bottom navigation">
                                                    <StyledPrevious targetRoute="/about" buttonText="Previous" />
                                                    <StyledSave targetRoute="/final-steps" buttonText="Save and Continue" />
                                                </StyledBottomNav>
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/final-steps"
                                    element={
                                        <FormLayout
                                            queryClient={queryClient}
                                            formPage={<FinalSteps />}
                                            bottomNav={
                                                <StyledBottomNav aria-label="bottom navigation">
                                                    <StyledPrevious targetRoute="/education" buttonText="Previous" />
                                                    <StyledSubmit<StudentAnswersKeys> buttonText="Submit Application" schema={schema} />
                                                </StyledBottomNav>
                                            }
                                        />
                                    }
                                />
                                <Route // DEFAULT PAGE
                                    element={<Navigate to={{ pathname: '/about' }} />}
                                    path="*"
                                />
                                <Route // ACCOUNT PAGES
                                    element={<SignUp />}
                                    path="/signup"
                                />
                                <Route element={<Confirm />} path="/confirm" />
                                <Route element={<Login />} path="/login" />
                                <Route element={<ForgotPassword />} path="/forgot" />
                                <Route element={<ResetPassword />} path="/reset" />

                                <Route // COMPLETION PAGE
                                    path="/completion"
                                    element={<Completion queryClient={queryClient} />}
                                />
                            </Routes>
                        </Suspense>
                    </StudentProfileProvider>
                </ApiWrapper.Root>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
