import { QueryClient } from '@tanstack/react-query';

// if you add a yupValueKey for a radio or select in the UserGeneratedAnswersEnum, you will need to add a corresponding yupValueKey for the description, specifically for radio inputs and selects (including those in AutoComplete components)
export enum AutoGeneratedAnswersEnum {
    gender_desc = 'gender_desc',
    mailing_state_desc = 'mailing_state_desc',
    mailing_country_desc = 'mailing_country_desc',
    birth_country_desc = 'birth_country_desc',
    birth_state_desc = 'birth_state_desc',
    citizen_country_desc = 'citizen_country_desc',
    citizenship_visa_status_desc = 'citizenship_visa_status_desc',
    military_branch_desc = 'military_branch_desc',
    military_status_specified_desc = 'military_status_specified_desc',
    military_benefits_desc = 'military_benefits_desc',
    prev_attend_from_month_desc = 'prev_attend_from_month_desc',
    prev_attend_to_month_desc = 'prev_attend_to_month_desc',
    prev_attend_from_year_desc = 'prev_attend_from_year_desc',
    prev_attend_to_year_desc = 'prev_attend_to_year_desc',
    college_2_degree_desc = 'college_2_degree_desc',
    college_2_attend_from_month_desc = 'college_2_attend_from_month_desc',
    college_2_attend_to_month_desc = 'college_2_attend_to_month_desc',
    college_2_attend_from_year_desc = 'college_2_attend_from_year_desc',
    college_2_attend_to_year_desc = 'college_2_attend_to_year_desc',
    college_2_state_desc = 'college_2_state_desc',
    college_3_degree_desc = 'college_3_degree_desc',
    college_3_attend_from_month_desc = 'college_3_attend_from_month_desc',
    college_3_attend_to_month_desc = 'college_3_attend_to_month_desc',
    college_3_attend_from_year_desc = 'college_3_attend_from_year_desc',
    college_3_attend_to_year_desc = 'college_3_attend_to_year_desc',
    college_3_state_desc = 'college_3_state_desc',
    college_4_degree_desc = 'college_4_degree_desc',
    college_4_attend_from_month_desc = 'college_4_attend_from_month_desc',
    college_4_attend_to_month_desc = 'college_4_attend_to_month_desc',
    college_4_attend_from_year_desc = 'college_4_attend_from_year_desc',
    college_4_attend_to_year_desc = 'college_4_attend_to_year_desc',
    college_4_state_desc = 'college_4_state_desc',
    college_5_degree_desc = 'college_5_degree_desc',
    college_5_attend_from_month_desc = 'college_5_attend_from_month_desc',
    college_5_attend_to_month_desc = 'college_5_attend_to_month_desc',
    college_5_attend_from_year_desc = 'college_5_attend_from_year_desc',
    college_5_attend_to_year_desc = 'college_5_attend_to_year_desc',
    college_5_state_desc = 'college_5_state_desc',
    gmat_taken_month_desc = 'gmat_taken_month_desc',
    gmat_taken_year_desc = 'gmat_taken_year_desc',
    gmat_plan_month_desc = 'gmat_plan_month_desc',
    gmat_plan_year_desc = 'gmat_plan_year_desc',
    gre_taken_month_desc = 'gre_taken_month_desc',
    gre_taken_year_desc = 'gre_taken_year_desc',
    gre_plan_month_desc = 'gre_plan_month_desc',
    gre_plan_year_desc = 'gre_plan_year_desc',
    toefl_taken_month_desc = 'toefl_taken_month_desc',
    toefl_taken_year_desc = 'toefl_taken_year_desc',
    toefl_plan_month_desc = 'toefl_plan_month_desc',
    toefl_plan_year_desc = 'toefl_plan_year_desc',
    ielts_taken_month_desc = 'ielts_taken_month_desc',
    ielts_taken_year_desc = 'ielts_taken_year_desc',
    ielts_plan_month_desc = 'ielts_plan_month_desc',
    ielts_plan_year_desc = 'ielts_plan_year_desc',
    conduct_question1_month_desc = 'conduct_question1_month_desc',
    conduct_question1_year_desc = 'conduct_question1_year_desc',
    conduct_question2_month_desc = 'conduct_question2_month_desc',
    conduct_question2_year_desc = 'conduct_question2_year_desc',
    conduct_question3_month_desc = 'conduct_question3_month_desc',
    conduct_question3_year_desc = 'conduct_question3_year_desc',
    conduct_question4_month_desc = 'conduct_question4_month_desc',
    conduct_question4_year_desc = 'conduct_question4_year_desc',
    conduct_question5_month_desc = 'conduct_question5_month_desc',
    conduct_question5_year_desc = 'conduct_question5_year_desc',
}

export enum UserGeneratedAnswersEnum {
    first_name = 'first_name',
    middle_name = 'middle_name',
    last_name = 'last_name',
    preferred_name = 'preferred_name',
    prev_lname = 'prev_lname',
    gender = 'gender',
    birth_date = 'birth_date',
    phone_home = 'phone_home',
    phone_mobile = 'phone_mobile',
    optin_sms_ind = 'optin_sms_ind',
    address_line_1 = 'address_line_1',
    address_line_2 = 'address_line_2',
    city = 'city',
    state = 'state',
    zip = 'zip',
    country = 'country',
    mailing_addr_same_as_perm_addr = 'mailing_addr_same_as_perm_addr',
    mailing_address_line_1 = 'mailing_address_line_1',
    mailing_address_line_2 = 'mailing_address_line_2',
    mailing_city = 'mailing_city',
    mailing_state = 'mailing_state',
    mailing_zip = 'mailing_zip',
    mailing_country = 'mailing_country',
    mailing_addr_effective_date = 'mailing_addr_effective_date',
    citizenship = 'citizenship',
    citizenship_type = 'citizenship_type',
    birth_country = 'birth_country',
    birth_state = 'birth_state',
    citizen_country = 'citizen_country',
    alien_registration_number = 'alien_registration_number',
    citizenship_visa_status = 'citizenship_visa_status',
    ethnicity = 'ethnicity',
    race_american_indian = 'race_american_indian',
    race_asian = 'race_asian',
    race_black = 'race_black',
    race_native_hawaiian = 'race_native_hawaiian',
    race_white = 'race_white',
    military_status_specified = 'military_status_specified',
    military_status = 'military_status',
    military_branch = 'military_branch',
    military_dependent = 'military_dependent',
    military_benefits = 'military_benefits',

    applied_major = 'applied_major',
    entry_term = 'entry_term',
    financial_aid = 'financial_aid',
    first_time_student = 'first_time_student',
    prev_attend = 'prev_attend',
    prev_attend_from_month = 'prev_attend_from_month',
    prev_attend_to_month = 'prev_attend_to_month',
    prev_attend_from_year = 'prev_attend_from_year',
    prev_attend_to_year = 'prev_attend_to_year',
    hs_name = 'hs_name',
    hs_ceeb = 'hs_ceeb',
    hs_city = 'hs_city',
    hs_state = 'hs_state',
    hs_zip = 'hs_zip',
    hs_address_line_1 = 'hs_address_line_1',
    hs_grad_month = 'hs_grad_month',
    hs_grad_year = 'hs_grad_year',
    college_degree = 'college_degree',
    college_gpa = 'college_gpa',
    college_credits = 'college_credits',
    college_attend_from_month = 'college_attend_from_month',
    college_attend_to_month = 'college_attend_to_month',
    college_attend_from_year = 'college_attend_from_year',
    college_attend_to_year = 'college_attend_to_year',
    college_2_degree = 'college_2_degree',
    college_2_gpa = 'college_2_gpa',
    college_2_credits = 'college_2_credits',
    college_2_attend_from_month = 'college_2_attend_from_month',
    college_2_attend_to_month = 'college_2_attend_to_month',
    college_2_attend_from_year = 'college_2_attend_from_year',
    college_2_attend_to_year = 'college_2_attend_to_year',
    college_3_degree = 'college_3_degree',
    college_3_gpa = 'college_3_gpa',
    college_3_credits = 'college_3_credits',
    college_3_attend_from_month = 'college_3_attend_from_month',
    college_3_attend_to_month = 'college_3_attend_to_month',
    college_3_attend_from_year = 'college_3_attend_from_year',
    college_3_attend_to_year = 'college_3_attend_to_year',
    college_4_degree = 'college_4_degree',
    college_4_gpa = 'college_4_gpa',
    college_4_credits = 'college_4_credits',
    college_4_attend_from_month = 'college_4_attend_from_month',
    college_4_attend_to_month = 'college_4_attend_to_month',
    college_4_attend_from_year = 'college_4_attend_from_year',
    college_4_attend_to_year = 'college_4_attend_to_year',
    college_5_degree = 'college_5_degree',
    college_5_gpa = 'college_5_gpa',
    college_5_credits = 'college_5_credits',
    college_5_attend_from_month = 'college_5_attend_from_month',
    college_5_attend_to_month = 'college_5_attend_to_month',
    college_5_attend_from_year = 'college_5_attend_from_year',
    college_5_attend_to_year = 'college_5_attend_to_year',
    college_name = 'college_name',
    college_2_name = 'college_2_name',
    college_3_name = 'college_3_name',
    college_4_name = 'college_4_name',
    college_5_name = 'college_5_name',
    college_address_line_1 = 'college_address_line_1',
    college_2_address_line_1 = 'college_2_address_line_1',
    college_3_address_line_1 = 'college_3_address_line_1',
    college_4_address_line_1 = 'college_4_address_line_1',
    college_5_address_line_1 = 'college_5_address_line_1',
    college_city = 'college_city',
    college_2_city = 'college_2_city',
    college_3_city = 'college_3_city',
    college_4_city = 'college_4_city',
    college_5_city = 'college_5_city',
    college_state = 'college_state',
    college_2_state = 'college_2_state',
    college_3_state = 'college_3_state',
    college_4_state = 'college_4_state',
    college_5_state = 'college_5_state',
    college_zip = 'college_zip',
    college_2_zip = 'college_2_zip',
    college_3_zip = 'college_3_zip',
    college_4_zip = 'college_4_zip',
    college_5_zip = 'college_5_zip',
    college_ceeb = 'college_ceeb',
    college_2_ceeb = 'college_2_ceeb',
    college_3_ceeb = 'college_3_ceeb',
    college_4_ceeb = 'college_4_ceeb',
    college_5_ceeb = 'college_5_ceeb',
    toefl_taken = 'toefl_taken',
    toefl_taken_month = 'toefl_taken_month',
    toefl_taken_year = 'toefl_taken_year',
    toefl_score = 'toefl_score',
    toefl_plan_month = 'toefl_plan_month',
    toefl_plan_year = 'toefl_plan_year',
    employ_current = 'employ_current',
    employer_name = 'employer_name',
    conviction = 'conviction',
    conviction_comment = 'conviction_comment',
    suspended = 'suspended',
    suspended_comment = 'suspended_comment',
    legal_agree = 'legal_agree',
    agree_counselor_request_waiver = 'agree_counselor_request_waiver',
}

export const StudentAnswersEnum = {
    ...AutoGeneratedAnswersEnum,
    ...UserGeneratedAnswersEnum,
};

export type StudentAnswersKeys = keyof typeof StudentAnswersEnum;
export type UserGeneratedAnswersKeys = keyof typeof UserGeneratedAnswersEnum;

export interface IQueryClientOnlyProps {
    queryClient: QueryClient;
}
