import { Separator, ThemeColor, ThemeShade, AccountPopover, ButtonWithIcon, IConditionObj, ConditionOperators } from '@papa/components';
import styled from 'styled-components';
import dropins from '../emails/dropins.json';
import { StyledHorizontalNav, StyledHorizontalNavMenuItem, StyledLink, StyledSeparator } from '../styles/FormElements';
import { QueryClient } from '@tanstack/react-query';
import { loadAboutYou, loadEducation, loadFinalSteps, loadLogin, loadSignUp } from '../App';
import { PreviewContent, WelcomeLoggedInMessage } from './WelcomeMessage';

export const conditionIsLoggedIn: IConditionObj<string> = {
    valueKey: 'is_logged_in',
    operator: ConditionOperators.equals,
    target: true,
};

export const StyledAcctPopover = styled(AccountPopover.Root)`
    z-index: 100;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &${AccountPopover.Trigger} {
        font-size: 1.15rem;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.secondary.main};
        &:hover {
            background-color: ${props => props.theme.primary.main};
        }
    }

    ${AccountPopover.AccountTrigger} {
        padding: 0.42em 0.65em;
        font-family: ${props => props.theme.font.bold};
        text-transform: uppercase;
        &:hover {
            ${AccountPopover.AccountIcon},${AccountPopover.AccountText} {
                color: ${props => props.theme.secondary.main};
            }
        }
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            gap: 0.5rem;
        }
    }

    &${AccountPopover.PopoverContainer} {
        background-color: ${props => props.theme.neutral.faint};
        right: 1rem;
        position: relative;
    }

    &${AccountPopover.AccountText}, &${AccountPopover.AccountIcon} {
        color: ${props => props.theme.white};
    }

    &${AccountPopover.AccountIcon} {
        padding: 0;
    }

    &${AccountPopover.SignInButton}, &${AccountPopover.SignUpButton} {
        box-shadow: none;
        border-radius: ${props => props.theme.borderRadius};
    }

    &${AccountPopover.SignUpButton} {
        color: ${props => props.theme.accent.main};
        text-decoration: underline;
        background-color: transparent;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &${AccountPopover.SignInButton} {
        font-family: ${props => props.theme.font.display};
        font-weight: 300;
        background-color: ${props => props.theme.primary.darkest};

        &:hover,
        &:focus {
            background-color: ${props => props.theme.secondary.main};
        }
    }

    &${AccountPopover.AccountPopoverClose} {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        justify-content: center;
        transition: none;
    }

    &${AccountPopover.CloseIcon} {
        transition: none;
        border: 2px solid ${props => props.theme.primary.darkest};
        border-radius: 100%;
        color: ${props => props.theme.primary.darkest};
        &:hover,
        &:focus {
            border: 2px solid ${props => props.theme.secondary.main};
            color: ${props => props.theme.secondary.main};
            background-color: ${props => props.theme.white};
        }
    }

    &${AccountPopover.LogoutButton} {
        font-family: ${props => props.theme.font.display};
        font-weight: 300;
        border-radius: ${props => props.theme.borderRadius};
        box-shadow: none;
        background-color: ${props => props.theme.primary.darkest};
        &:hover {
            background-color: ${props => props.theme.secondary.main};
        }
        &:disabled {
            background-color: ${props => props.theme.disabled.light};
        }

        &&&${ButtonWithIcon.LoadingContainer} {
            background-color: transparent;
            padding: 0.4rem 0.85rem;
            outline: none;
            border: 0;
        }
    }
`;
export const ApplicationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 0rem;
    width: 92%;
    margin: 0 auto;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        padding: ${props => props.theme.padding.applicationHeader};
    }
`;
const SchoolLogo = styled.img`
    width: 15rem;
    height: 3rem;
    @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
        width: ${props => props.theme.font.sizes.schoolLogoWidth};
        height: ${props => props.theme.font.sizes.schoolLogoHeight};
    }
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        width: 13rem;
        height: 2rem;
    }
`;
const AppTitle = styled.h2`
    color: ${props => props.theme.secondary.light};
    font-family: ${props => props.theme.font.main};
    font-size: 1em;
    text-indent: -9999em;
    background: url(img-app-title-2.svg) no-repeat right center/contain;
    height: 3rem;
    width: 18rem;
    @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
        height: calc(2rem + 1 * ((100vw - 37.5rem) / 26.5));
        width: calc(15rem + 3 * ((100vw - 48rem) / 27));
        margin-bottom: 0;
    }
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: ${props => props.theme.font.sizes.normal};
        width: 15rem;
        height: 2rem;
    }
`;

const Banner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/img-aerial2.jpg) no-repeat center/cover;
    width: 100%;
    height: 100%;
    min-height: 436px;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        height: auto;
        max-height: 100%;
    }
`;

const WelcomeMessage = styled.div`
    color: ${props => props.theme.white};
    font-family: ${props => props.theme.font.display};
    max-width: 48rem;
    padding: 4vw 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        width: 92%;
        text-align: left;
    }
`;

const NavAlignmentWrapper = styled.div`
    width: 92%;
    margin: 0 auto;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }
`;
const NumberMenu = styled.span`
    font-weight: bold;
    font-size: 1.15rem;
    padding-right: 0.35rem;
    color: ${props => props.theme.secondary.light};
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1rem;
    }
`;
const TextMenu = styled.span`
    font-weight: bold;
    position: relative;
    padding-left: 0.45rem;
    &::before {
        content: '';
        font-size: 1.15rem;
        border-left: 2px solid ${props => props.theme.primary.dark};
        font-family: ${props => props.theme.font.display};
        position: absolute;
        top: 6px;
        left: 0px;
        height: 1rem;
    }
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: calc(${props => props.theme.font.sizes.menu});
        display: none;
    }
`;

export const LogoAndTitle = () => {
    return (
        <LogoContainer>
            <SchoolLogo src="/img-logo-horz.svg" alt={`Logo for ${dropins.partner_name}`} width="150" height="150" />
            <AppTitle>{dropins.application_title}</AppTitle>
        </LogoContainer>
    );
};
export const PopoverAndTitleContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    position: relative;
    justify-content: flex-end;
    gap: 0.5rem;
`;

export const WelcomeBanner = (props: { queryClient: QueryClient; hasWelcomeMessage: boolean }) => {
    return (
        <div role="banner">
            <PopoverAndTitleContainer>
                <StyledAcctPopover queryClient={props.queryClient} loaders={{ signUp: loadSignUp, login: loadLogin }} />
            </PopoverAndTitleContainer>
            <NavAlignmentWrapper>
                <ApplicationHeader>
                    <LogoAndTitle />
                </ApplicationHeader>
                <StyledHorizontalNav>
                    <StyledHorizontalNavMenuItem route="/about" loader={loadAboutYou}>
                        <NumberMenu>1</NumberMenu>
                        <TextMenu>About You</TextMenu>
                    </StyledHorizontalNavMenuItem>
                    <StyledHorizontalNavMenuItem route="/education" loader={loadEducation}>
                        <NumberMenu>2</NumberMenu>
                        <TextMenu>Education</TextMenu>
                    </StyledHorizontalNavMenuItem>
                    <StyledHorizontalNavMenuItem route="/final-steps" loader={loadFinalSteps}>
                        <NumberMenu>3</NumberMenu>
                        <TextMenu>Final Steps</TextMenu>
                    </StyledHorizontalNavMenuItem>
                </StyledHorizontalNav>
            </NavAlignmentWrapper>
            <Banner>
                <WelcomeMessage>
                    <WelcomeLoggedInMessage />
                    <StyledSeparator orientation={Separator.Orientations.horizontal} weight="thin" color={ThemeColor.neutral} shade={ThemeShade.light} />
                    <PreviewContent>
                        <strong>Important Note for RN to BSN Applicants:</strong>
                    </PreviewContent>
                    <PreviewContent>
                        If you plan to apply to the RN to BSN program, please{' '}
                        <StyledLink target="_blank" href={dropins.nursing_link}>
                            apply via NursingCAS
                        </StyledLink>
                        .
                    </PreviewContent>
                </WelcomeMessage>
            </Banner>
        </div>
    );
};
