import { ResponsiveLayout, ThemeColor, ThemeShade, ModalButton, ModalContent, MaterialIcon } from '@papa/components';
import styled from 'styled-components';
import { PartnerMailToLink } from '../Pages/Form/FormHelpers';
import dropins from '../emails/dropins.json';
import { StyledLinkFooter } from '../styles/FormElements';
import { ICustomTheme } from '../styles/theme';

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin: auto;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        flex-direction: column;
    }
`;
const FooterLayout = styled(ResponsiveLayout.Root)`
    &${ResponsiveLayout.Container} {
        background-color: ${props => props.theme.secondary.main};
        color: ${props => props.theme.white};
        font-family: ${props => props.theme.font.main};
    }

    ${ResponsiveLayout.Content} {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 0;
        min-height: 4rem;
        width: 100%;
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            padding: 1rem 0;
        }
    }
`;

export const AddressContainer = styled.div`
    margin: 0 0 1rem 0;
    font-style: 400;
    font-size: 0.9375rem;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        line-height: 1.4;
    }
`;

export const AddressPartnerName = styled.div`
    font-family: ${props => props.theme.font.display};
    font-size: 1.15rem;
`;

export const FooterLink = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 0 0 1rem 0;
`;
export const Footer = () => {
    return (
        <footer>
            <FooterLayout>
                <LinkAndAddress color={ThemeColor.neutral} shade={ThemeShade.light} />
            </FooterLayout>
        </footer>
    );
};

interface ILinkAndAddressProps {
    color: keyof typeof ThemeColor;
    shade: keyof typeof ThemeShade;
}
export const LinkAndAddress = (props: ILinkAndAddressProps) => {
    const { color, shade } = props;
    return (
        <FooterWrapper>
            <AddressContainer>
                <AddressPartnerName>{dropins.partner_name}</AddressPartnerName>
                <div>{dropins.partner_address_1}</div>
                <div>{dropins.partner_address_2}</div>
            </AddressContainer>

            <FooterLink>
                <StyledHelpLink color={color} shade={shade} />
                <StyledPrivacyButton color={color} shade={shade} />
            </FooterLink>
        </FooterWrapper>
    );
};

const modalStyles = (props: { theme: ICustomTheme }) => `
&${ModalButton.Trigger} {
    display: flex;
    align-items: center;
    color: ${props.theme.white};
    transition: ${props.theme.transition};
    font-weight: 700;
    font-family: ${props.theme.font.main};
    gap: 0.5rem;
    font-size: 0.9375rem;
    cursor: pointer;
    text-decoration: underline;
    &:hover,
    &:focus {
        text-decoration: none;
        color: ${props.theme.primary.dark};
    }
}
&${ModalContent.Header} {
    font-size: calc(${props.theme.font.sizes.header});
    line-height: 1.2;
    font-family: ${props.theme.font.bold};
    font-weight: 300;
    color: #444;
}
&${ModalContent.CloseButton} {
    color: ${props.theme.primary.dark};
    cursor: pointer;
    &:hover,
    &:focus {
        color: ${props.theme.secondary.main};
    }
}
&${MaterialIcon.Root} {
    border: 2px solid ${props.theme.primary.dark};
    border-radius: 100%;
    &:hover,
    &:focus {
        border: 2px solid ${props.theme.secondary.main};
    }
}

`;
interface IStyledModalProps {
    className?: string;
}
const HelpLink = (props: IStyledModalProps) => {
    return (
        <ModalButton.Root
            buttonText="Help"
            modalHeader="TECHNICAL HELP"
            size="xs"
            className={props.className}
            wrapModalContent={false}
            modalContent={
                <>
                    <p>
                        For technical assistance using this application, please email us at <PartnerMailToLink dropinsKey="partner_email" />. We'll get back to you ASAP.
                    </p>
                </>
            }
        />
    );
};

const PrivacyLink = (props: IStyledModalProps) => {
    const currentYear = new Date().getFullYear();
    const copyrightYear = `© ${currentYear}`;

    return (
        <ModalButton.Root
            buttonText="Privacy"
            modalHeader="PRIVACY"
            size="m"
            className={props.className}
            modalContent={
                <>
                    <ModalHeader>Cookies</ModalHeader>
                    <p>
                        This website uses a "cookie," a small text file that is sent to your computer when you first visit. The cookie is stored on your computer and
                        allows you to be recognized when you visit again later. We use cookies to enhance your visits, most often by prefilling your name and other
                        information on forms you submit to us.
                    </p>
                    <p>
                        This website uses remarketing services, such as Google, Facebook and Adroll, to advertise on third-party websites to previous visitors of our
                        site. Third-party vendors place cookies on users' browsers for targeted advertising purposes and serve ads to locations such as the Google search
                        results page or any site in the Google Display Network based on someone's past visits to the website.{' '}
                    </p>
                    <p>
                        Data such as identification of your internet browser or computer operating system may be collected for targeted advertising. This information is
                        collected to track usage behavior and compile aggregate data to facilitate content improvements. You may opt out of Google's use of cookies by
                        visiting{' '}
                        <StyledLinkFooter href="https://www.google.com/settings/u/0/ads/authenticated" target="_blank">
                            Google's Ads Settings
                        </StyledLinkFooter>{' '}
                        and{' '}
                        <StyledLinkFooter href="http://www.networkadvertising.org/choices/" target="_blank">
                            opt out of the additional use of cookies by visiting this site
                        </StyledLinkFooter>
                        .
                    </p>
                    <p>
                        Google Drive: When you visit certain EAB websites, we may allow users with Google Drive accounts to attach documents from their Google Drive
                        Account to a submitted college enrollment application. The EAB website receives documents selected from a user's Google Drive account during the
                        application process. Such documents are stored temporarily and included in the user's complete, delivered application.
                    </p>
                    <p>
                        You can{' '}
                        <StyledLinkFooter href={dropins.privacy_link} target="_blank">
                            view the complete privacy policy for {dropins.partner_name} here
                        </StyledLinkFooter>
                        .
                    </p>
                    <i>
                        {copyrightYear} {dropins.copyright}
                    </i>
                </>
            }
        />
    );
};
export const StyledHelpLink = styled(HelpLink)<ILinkAndAddressProps>`
    ${modalStyles};
`;

export const StyledPrivacyButton = styled(PrivacyLink)<ILinkAndAddressProps>`
    ${modalStyles};
`;
export const ModalHeader = styled.h3`
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    font-family: ${props => props.theme.font.display};
`;
