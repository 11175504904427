import { UserGeneratedAnswersEnum } from './types';

export const pagesToQuestions = {
    about: [
        UserGeneratedAnswersEnum.first_name,
        UserGeneratedAnswersEnum.middle_name,
        UserGeneratedAnswersEnum.last_name,
        UserGeneratedAnswersEnum.preferred_name,
        UserGeneratedAnswersEnum.prev_lname,
        UserGeneratedAnswersEnum.gender,
        UserGeneratedAnswersEnum.birth_date,
        UserGeneratedAnswersEnum.phone_home,
        UserGeneratedAnswersEnum.phone_mobile,
        UserGeneratedAnswersEnum.optin_sms_ind,
        UserGeneratedAnswersEnum.address_line_1,
        UserGeneratedAnswersEnum.address_line_2,
        UserGeneratedAnswersEnum.city,
        UserGeneratedAnswersEnum.state,
        UserGeneratedAnswersEnum.zip,
        UserGeneratedAnswersEnum.country,
        UserGeneratedAnswersEnum.mailing_addr_same_as_perm_addr,
        UserGeneratedAnswersEnum.mailing_address_line_1,
        UserGeneratedAnswersEnum.mailing_address_line_2,
        UserGeneratedAnswersEnum.mailing_city,
        UserGeneratedAnswersEnum.mailing_state,
        UserGeneratedAnswersEnum.mailing_zip,
        UserGeneratedAnswersEnum.mailing_country,
        UserGeneratedAnswersEnum.mailing_addr_effective_date,
        UserGeneratedAnswersEnum.citizenship_type,
        UserGeneratedAnswersEnum.birth_country,
        UserGeneratedAnswersEnum.birth_state,
        UserGeneratedAnswersEnum.citizen_country,
        UserGeneratedAnswersEnum.alien_registration_number,
        UserGeneratedAnswersEnum.citizenship_visa_status,
        UserGeneratedAnswersEnum.ethnicity,
        UserGeneratedAnswersEnum.race_american_indian,
        UserGeneratedAnswersEnum.race_asian,
        UserGeneratedAnswersEnum.race_black,
        UserGeneratedAnswersEnum.race_native_hawaiian,
        UserGeneratedAnswersEnum.race_white,
        UserGeneratedAnswersEnum.military_status,
        UserGeneratedAnswersEnum.military_branch,
        UserGeneratedAnswersEnum.military_status_specified,
        UserGeneratedAnswersEnum.military_dependent,
        UserGeneratedAnswersEnum.military_benefits,
    ],
    education: [
        UserGeneratedAnswersEnum.applied_major,
        UserGeneratedAnswersEnum.entry_term,
        UserGeneratedAnswersEnum.financial_aid,
        UserGeneratedAnswersEnum.first_time_student,
        UserGeneratedAnswersEnum.prev_attend,
        UserGeneratedAnswersEnum.prev_attend_from_month,
        UserGeneratedAnswersEnum.prev_attend_to_month,
        UserGeneratedAnswersEnum.prev_attend_from_year,
        UserGeneratedAnswersEnum.prev_attend_to_year,
        UserGeneratedAnswersEnum.hs_name,
        UserGeneratedAnswersEnum.hs_ceeb,
        UserGeneratedAnswersEnum.hs_city,
        UserGeneratedAnswersEnum.hs_state,
        UserGeneratedAnswersEnum.hs_zip,
        UserGeneratedAnswersEnum.hs_address_line_1,
        UserGeneratedAnswersEnum.hs_grad_month,
        UserGeneratedAnswersEnum.hs_grad_year,
        UserGeneratedAnswersEnum.college_degree,
        UserGeneratedAnswersEnum.college_gpa,
        UserGeneratedAnswersEnum.college_credits,
        UserGeneratedAnswersEnum.college_attend_from_month,
        UserGeneratedAnswersEnum.college_attend_to_month,
        UserGeneratedAnswersEnum.college_attend_from_year,
        UserGeneratedAnswersEnum.college_attend_to_year,
        UserGeneratedAnswersEnum.college_2_degree,
        UserGeneratedAnswersEnum.college_2_gpa,
        UserGeneratedAnswersEnum.college_2_credits,
        UserGeneratedAnswersEnum.college_2_attend_from_month,
        UserGeneratedAnswersEnum.college_2_attend_to_month,
        UserGeneratedAnswersEnum.college_2_attend_from_year,
        UserGeneratedAnswersEnum.college_2_attend_to_year,
        UserGeneratedAnswersEnum.college_3_degree,
        UserGeneratedAnswersEnum.college_3_gpa,
        UserGeneratedAnswersEnum.college_3_credits,
        UserGeneratedAnswersEnum.college_3_attend_from_month,
        UserGeneratedAnswersEnum.college_3_attend_to_month,
        UserGeneratedAnswersEnum.college_3_attend_from_year,
        UserGeneratedAnswersEnum.college_3_attend_to_year,
        UserGeneratedAnswersEnum.college_4_degree,
        UserGeneratedAnswersEnum.college_4_gpa,
        UserGeneratedAnswersEnum.college_4_credits,
        UserGeneratedAnswersEnum.college_4_attend_from_month,
        UserGeneratedAnswersEnum.college_4_attend_to_month,
        UserGeneratedAnswersEnum.college_4_attend_from_year,
        UserGeneratedAnswersEnum.college_4_attend_to_year,
        UserGeneratedAnswersEnum.college_5_degree,
        UserGeneratedAnswersEnum.college_5_gpa,
        UserGeneratedAnswersEnum.college_5_credits,
        UserGeneratedAnswersEnum.college_5_attend_from_month,
        UserGeneratedAnswersEnum.college_5_attend_to_month,
        UserGeneratedAnswersEnum.college_5_attend_from_year,
        UserGeneratedAnswersEnum.college_5_attend_to_year,
        UserGeneratedAnswersEnum.college_name,
        UserGeneratedAnswersEnum.college_address_line_1,
        UserGeneratedAnswersEnum.college_city,
        UserGeneratedAnswersEnum.college_state,
        UserGeneratedAnswersEnum.college_zip,
        UserGeneratedAnswersEnum.college_ceeb,
        UserGeneratedAnswersEnum.college_2_name,
        UserGeneratedAnswersEnum.college_2_address_line_1,
        UserGeneratedAnswersEnum.college_2_city,
        UserGeneratedAnswersEnum.college_2_state,
        UserGeneratedAnswersEnum.college_2_zip,
        UserGeneratedAnswersEnum.college_2_ceeb,
        UserGeneratedAnswersEnum.college_3_name,
        UserGeneratedAnswersEnum.college_3_address_line_1,
        UserGeneratedAnswersEnum.college_3_city,
        UserGeneratedAnswersEnum.college_3_state,
        UserGeneratedAnswersEnum.college_3_zip,
        UserGeneratedAnswersEnum.college_3_ceeb,
        UserGeneratedAnswersEnum.college_4_name,
        UserGeneratedAnswersEnum.college_4_address_line_1,
        UserGeneratedAnswersEnum.college_4_city,
        UserGeneratedAnswersEnum.college_4_state,
        UserGeneratedAnswersEnum.college_4_zip,
        UserGeneratedAnswersEnum.college_4_ceeb,
        UserGeneratedAnswersEnum.college_5_name,
        UserGeneratedAnswersEnum.college_5_address_line_1,
        UserGeneratedAnswersEnum.college_5_city,
        UserGeneratedAnswersEnum.college_5_state,
        UserGeneratedAnswersEnum.college_5_zip,
        UserGeneratedAnswersEnum.college_5_ceeb,
        UserGeneratedAnswersEnum.toefl_taken,
        UserGeneratedAnswersEnum.toefl_taken_month,
        UserGeneratedAnswersEnum.toefl_taken_year,
        UserGeneratedAnswersEnum.toefl_score,
        UserGeneratedAnswersEnum.toefl_plan_month,
        UserGeneratedAnswersEnum.toefl_plan_year,
        UserGeneratedAnswersEnum.employ_current,
        UserGeneratedAnswersEnum.employer_name,
    ],
    'final-steps': [
        UserGeneratedAnswersEnum.conviction,
        UserGeneratedAnswersEnum.conviction_comment,
        UserGeneratedAnswersEnum.suspended,
        UserGeneratedAnswersEnum.suspended_comment,
        UserGeneratedAnswersEnum.legal_agree,
        UserGeneratedAnswersEnum.agree_counselor_request_waiver,
    ],
};
