import { SaveButton, SubmitButton, Form, MaterialIcon, ButtonWithIcon, ThemeColor, ThemeShade, Separator } from '@papa/components';
import { QueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { schema } from '../../dataModel/yupSchema';
import {
    actionButtonStyling,
    backButtonStyling,
    CustomLayout,
    ErrorStyledSectionHeader,
    Flex,
    LeadingSeparator,
    StyledSeparator,
    StyledValidationErrorSummary,
    NavCustomLayout,
} from '../../styles/FormElements';
import { Footer } from '../../Components/Footer';
import { WelcomeBanner } from '../../Components/WelcomeBanner';
import { Subtext, SurroundingForwardSlashes } from './FormHelpers';
import React from 'react';
import { pagesToQuestions } from '../../dataModel/pagesToQuestions';
import { FontSizesEnum } from '../../styles/theme';

const StyledActionButton = (Button: (props: any) => JSX.Element) => styled(Button)`
    ${actionButtonStyling}
    font-size: 1.35rem;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        text-align: center;
        display: block;
        border-radius: 0;
    }
    &${ButtonWithIcon.LoadingContainer} {
        background-color: transparent;
        margin-left: 0.4rem;
    }

    &${MaterialIcon.Root} {
        background-color: transparent;
    }
`;

export const StyledSave = StyledActionButton(SaveButton.Root);
export const StyledSubmit = StyledActionButton(SubmitButton.Root) as typeof SubmitButton.Root;

export const StyledPrevious = styled(SaveButton.Root)`
    ${backButtonStyling}
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        text-align: center;
        display: block;
        border-radius: 0;
    }
`;

export const StyledBottomNav = styled.nav<{ 'justify-content'?: string }>`
    display: flex;
    justify-content: ${props => props['justify-content'] || 'space-between'};
    gap: 2rem;
    width: 100%;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        flex-direction: column-reverse;
        text-align: center;
        padding: ${props => props.theme.padding.bottomNavPadding};
    }
`;

interface IFormLayoutProps {
    bottomNav: JSX.Element | JSX.Element[];
    queryClient: QueryClient;
    formPage: JSX.Element | JSX.Element[];
}
export const FormLayout = (props: IFormLayoutProps) => {
    const { bottomNav, queryClient, formPage } = props;
    return (
        <>
            <WelcomeBanner queryClient={queryClient} hasWelcomeMessage />
            <Form.Root>
                <>
                    <SurroundingForwardSlashes accountPage={false}>
                        <CustomLayout>
                            <>
                                <StyledValidationErrorSummary
                                    label={
                                        <React.Fragment key="label">
                                            <Flex>
                                                <ErrorStyledSectionHeader>We're missing some information!</ErrorStyledSectionHeader>
                                            </Flex>
                                            <LeadingSeparator
                                                orientation={Separator.Orientations.horizontal}
                                                weight="regular"
                                                color={ThemeColor.error}
                                                shade={ThemeShade.main}
                                            />
                                            <Subtext size={FontSizesEnum.normal} lineHeight="1.6" color={ThemeColor.secondary} shade={ThemeShade.main} fontWeight="500">
                                                The links below show what needs to be corrected. Missing or incorrect items will be highlighted on each page; simply fix
                                                them and then resubmit your application.
                                            </Subtext>
                                            <StyledSeparator orientation="horizontal" weight="thin" color={ThemeColor.white} shade={ThemeShade.main} />
                                        </React.Fragment>
                                    }
                                    schema={schema}
                                    pagesToQuestions={pagesToQuestions}
                                />
                                {formPage}
                            </>
                        </CustomLayout>
                    </SurroundingForwardSlashes>
                    <NavCustomLayout>{bottomNav}</NavCustomLayout>
                </>
            </Form.Root>
            <Footer />
        </>
    );
};
