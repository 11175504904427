import {
    ResponsiveLayout,
    Separator,
    StyledSection,
    HorizontalNav,
    HorizontalNavMenuItem,
    AddressBlock,
    CeebBlock,
    RadioInput,
    ThemeColor,
    ThemeShade,
    DependentSelect,
    ValidationErrorSummary,
    SharedErrorStyling,
    MaterialIcon,
    Toast,
    ITheme,
    ButtonWithIcon,
    AddAnotherButton,
    StyledInput,
    TextInput,
    Label,
    MonthDate,
    Select,
    DatePicker,
    MaskedInput,
    ToggleVisibility,
    MaskedPhone,
    SingleCheckInput,
    FormWrapper,
    CheckInputGroup,
    TextArea,
} from '@papa/components';
import { CSSObjectWithLabel, OptionProps, ControlProps } from 'react-select';
import styled from 'styled-components';
import { ICustomTheme, theme } from './theme';

const commonBlockStyles = (props: { theme: ICustomTheme }) => `
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1.6fr;
    width: 100%;
    padding: 0.5rem 0;
    @media screen and (max-width: calc(${props.theme.layout.tablet} - 1px)) {
        display: block;
    }
`;

const defaultButtonStyling = `
    align-items: center;
    display: flex;
    box-shadow: none;
    cursor: pointer;
    line-height: 1.2;
    font-weight: bold;
`;

const navButtonStyling = `
    ${defaultButtonStyling};
    font-size: 1.35em;
    padding: 0.65em 1.25em;
    box-sizing: content-box;
`;

export const actionButtonStyling = (props: { theme: ICustomTheme }) => `
    ${navButtonStyling};
    font-family: ${props.theme.font.display};
    background-color: ${props.theme.primary.darkest};
    color: ${props.theme.neutral.faint};
    
    &:hover,
    &:focus {
        transition: ${props.theme.transition};
        background-color: ${props.theme.secondary.main};
    }

    &[disabled],
    &[disabled] &:hover,
    &[disabled] &:focus {
        background-color: ${props.theme.disabled.main};
    }
    border-radius: ${props.theme.borderRadius};
`;

export const backButtonStyling = (props: { theme: ICustomTheme }) => `
    ${navButtonStyling};
    font-family: ${props.theme.font.display};
    background-color: ${props.theme.secondary.main};
    color: ${props.theme.neutral.faint};

    &:hover,
    &:focus {
        background-color: ${props.theme.primary.dark};
        color: ${props.theme.neutral.faint};
        transition: ${props.theme.transition};
    }
    border-radius: ${props.theme.borderRadius};
`;

export const commonLabelStyles = (props: { theme: ICustomTheme }) => `
    font-size: ${props.theme.font.sizes.normal};
    line-height: 1.4;
    font-family: ${props.theme.font.main};
    color: ${props.theme.secondary.main};
    @media screen and (max-width: calc(${props.theme.layout.mobile} - 1px)) {
        padding: 0.5rem 0 0.2rem 0;
    }
`;

export const commonInputFieldStyleRules = (props: { theme: ICustomTheme }) => `
    padding: 0.5rem 0.5rem;
    border: 1px solid ${props.theme.neutral.light};
    background: ${props.theme.primary.input};
    color: ${props.theme.secondary.dark};
    font-size: ${props.theme.font.sizes.normal};
    line-height: 1.6;
    transition: ${props.theme.transition};
    border-radius: 0;
    &:hover {
        z-index: 1;
        background:${props.theme.white};
    }
    &:focus{
        z-index: 1;
        box-shadow: ${props.theme.boxShadow.input};
        border: 1px solid ${props.theme.secondary.light};
    }
`;
const commonDropDownStyleRules = (props: { theme: ICustomTheme }) => `
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0.75em;
    cursor: pointer;
    border-width: 1px 1px 0 1px;
    color: ${props.theme.secondary.main};
    &:hover, &.focus{
        background-color: ${props.theme.neutral.extraLight};
    }

    @media screen and (max-width: calc(${props.theme.layout.mobile} - 1px)) {
        width: 92%;
    }
`;
export const smallDeviceIconWidth = (props: { theme: ICustomTheme }) => `
    &${MaterialIcon.Root} {
        @media screen and (max-width: calc(${props.theme.layout.mobile} - 1px)) {
            width: 2rem;
        }
    }
`;

export const commonMobileDeviceMediaQuery = (props: { theme: ICustomTheme }) => `
    @media screen and (max-width: calc(${props.theme.layout.mobile} - 1px)) {
        width: 100%;
    }
`;

export const StyledTextArea = styled(TextArea.Root)`
    &${TextArea.Text} {
        border: 1px solid ${props => props.theme.neutral.light};
        background: ${props => props.theme.primary.input};
        color: ${props => props.theme.secondary.dark};
        font-size: ${props => props.theme.font.sizes.normal};
        transition: ${props => props.theme.transition};
        &:hover,
        &:focus {
            z-index: 1;
            background: ${props => props.theme.white};
        }
        &:focus {
            box-shadow: ${props => props.theme.boxShadow.input};
            border: 1px solid ${props => props.theme.secondary.light};
        }
    }
`;

export const errorToastStyles = (props: { theme: ITheme }) => `
    &${Toast.Container} {
        background-color: ${props.theme.error.light};
        box-shadow: none;
        color: ${props.theme.error.main};
        border: 1px solid ${props.theme.error.main};
    }

    &${Toast.Viewport} {
        background-color: transparent;
    }

    &${Toast.Title} {
        font-family: ${props.theme.font.display};
        font-weight: 700;
        margin: 0;
    }
`;

export const StyledAddAnother = styled(AddAnotherButton.Root)`
    &${ButtonWithIcon.Button} {
        ${defaultButtonStyling};
        font-family: ${props => props.theme.font.bold};
        font-size: 1.15rem;
        color: ${props => props.theme.white};
        background: ${props => props.theme.secondary.main};
        padding: 0.42em 0.65em;
        text-transform: uppercase;
        margin-top: 2rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        min-width: 18rem;
        min-height: 3rem;

        &:hover,
        &:focus {
            background-color: ${props => props.theme.primary.main};
            color: ${props => props.theme.secondary.main};
            transition: ${props => props.theme.transition};
        }
    }
    &&&${ButtonWithIcon.LoadingContainer} {
        background-color: transparent;
    }

    &${MaterialIcon.Root} {
        background-color: transparent;
        margin-top: 0;
        font-size: 1.15rem;
    }

    ${errorToastStyles};

    ${Toast.Container} {
        margin: 1rem 0 0 0;
    }
` as typeof AddAnotherButton.Root;

export const commonHeaderStyles = (props: { theme: ITheme }) => `
    font-family: ${props.theme.font.bold};
    font-weight: 300;
    font-size: 2.25rem;
    margin: 0;
    color: ${props.theme.secondary.main};
`;

export const StackedFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledValidationErrorSummary = styled(ValidationErrorSummary.Root)`
    &${ValidationErrorSummary.RootContainer} {
        background-color: ${props => props.theme.white};
        box-shadow: ${props => props.theme.boxShadow.main};
        border-top: 10px solid ${props => props.theme.error.main};
        padding: 1rem 2rem 2rem 2rem;
        border-radius: ${props => props.theme.borderRadius};
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            padding: calc(${props => props.theme.padding.validationErrorSummary});
        }
    }
    &${SharedErrorStyling.Item} {
        cursor: pointer;
        padding-left: 0.5rem;
        margin-left: -0.5rem;
        align-items: center;
        &:hover,
        &:focus {
            background-color: ${props => props.theme.error.main}10;
        }
    }
    &${SharedErrorStyling.Container} {
        color: ${props => props.theme.error.main};
        transition: ${props => props.theme.transition};
    }
    &${ValidationErrorSummary.MessageLink} {
        transition: ${props => props.theme.transition};
        text-decoration: none;
        font-style: italic;
        font-weight: 600;
        padding: 0;
        &:hover,
        &:focus {
            color: ${props => props.theme.secondary.dark};
        }
    }
` as typeof ValidationErrorSummary.Root;

export const BlockRadioInput = styled(RadioInput.Root)`
    &${RadioInput.RadioGroup} {
        outline: none;
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 0.7rem;
    }
    &${Label} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            padding-bottom: 0.5rem;
        }
    }
` as typeof RadioInput.Root;
export const StyledAddressBlock = styled(AddressBlock.Root)`
    &${AddressBlock.ElementContainer} {
        ${commonBlockStyles};
    }

    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }
    &${Separator.Root} {
        margin: 0.5rem 0;
        height: 1px;
    }
    &${Select.Trigger} {
        ${commonInputFieldStyleRules};
        padding: 0.5rem;
    }
    &${Label} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            padding-bottom: 0.5rem;
        }
    }
    &${Select.Item} {
        ${commonDropDownStyleRules};
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    &${Select.Icon} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            width: 2rem;
        }
    }
    &${Separator.Root} {
        background-color: ${props => props.theme.neutral.extraLight};
    }
` as typeof AddressBlock.Root;

export const StyledCeebBlock = styled(CeebBlock.Root)`
    &${CeebBlock.ElementContainer} {
        ${commonBlockStyles};
    }

    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }
    &${Separator.Root} {
        margin: 0.5rem 0;
        height: 1px;
    }
    &${Select.Trigger} {
        ${commonInputFieldStyleRules};
        padding: 0.5rem;
    }
    &${Select.ItemIndicator} {
        background-color: ${props => props.theme.neutral.selectIcon};
    }
    &${Label} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            padding-bottom: 0.5rem;
        }
    }
    &${Select.Item} {
        ${commonDropDownStyleRules};
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    &${Select.Icon} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            width: 2rem;
        }
    }
    &${Separator.Root} {
        background-color: ${props => props.theme.neutral.extraLight};
    }
` as typeof CeebBlock.Root;

export const StyledDependentSelect = styled(DependentSelect.Root)`
    &${DependentSelect.ElementContainer} {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1.62fr;
        width: 100%;
        padding: 1rem 0;
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            display: block;
        }
    }
` as typeof DependentSelect.Root;

export const Flex = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const StyledLink = styled.a`
    color: ${props => props.theme.primary.dark};
    transition: ${props => props.theme.transition};
    font-weight: bold;
    &:hover,
    &:focus {
        text-decoration: none;
        color: ${props => props.theme.white};
    }
`;

export const StyledLinkErrorMessage = styled.a`
    color: ${props => props.theme.white};
    transition: ${props => props.theme.transition};
    font-weight: bold;
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;

export const StyledLinkFooter = styled.a`
    color: ${props => props.theme.accent.main};
    transition: ${props => props.theme.transition};
    font-weight: bold;
    &:visited {
        color: ${props => props.theme.accent.dark};
    }
    &:hover,
    &:focus {
        text-decoration: none;
        color: ${props => props.theme.accent.dark};
    }
`;

export const StyledSeparator = styled(Separator.Root)`
    margin: 1rem 0;
    height: 1px;
` as typeof Separator.Root;

export const StyledDarkSeparator = styled(Separator.Root)`
    margin: 2rem 0 1rem 0;
    height: 2px;
` as typeof Separator.Root;

export const StyledSeparatorLight = styled(Separator.Root)`
    margin: 1rem 0;
    height: 1px;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        display: none;
    }
` as typeof Separator.Root;

export const lightSeparator = (
    <StyledSeparatorLight orientation={Separator.Orientations.horizontal} weight="thin" color={ThemeColor.neutral} shade={'extraLight' as any} />
); /* FIXME https://jira.devops.eab.com/browse/ESMA-2783 */
export const boldSeparator = <StyledDarkSeparator orientation={Separator.Orientations.horizontal} weight="bold" color={ThemeColor.primary} shade={ThemeShade.dark} />;

export const ErrorStyledSectionHeader = styled(StyledSection.Header)`
    font-family: ${props => props.theme.font.bold};
    font-weight: 300;
    text-transform: uppercase;
    margin: 0.25rem;
    color: ${props => props.theme.error.main};
    font-size: calc(${props => props.theme.font.sizes.header});
`;

export const ErrorStyledIcon = styled(MaterialIcon.Root)`
    color: ${props => props.theme.error.main};
    align-self: center;
    font-size: 2.2em;
`;

export const CustomSection = styled(StyledSection.Root)`
    &${StyledSection.Header} {
        ${commonHeaderStyles};
        line-height: 1.2;
        text-transform: uppercase;
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            font-size: 1.85rem;
            padding-bottom: 0.25rem;
        }
    }

    &${StyledSection.Container} {
        box-shadow: ${props => props.theme.boxShadow.main};
        padding: 2rem;
        background-color: ${props => props.theme.white};
        border-radius: ${props => props.theme.borderRadius};
        border-bottom: 10px solid ${props => props.theme.primary.main};
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            padding: calc(${props => props.theme.padding.styledSection});
        }
    }

    &${Separator.Root} {
        margin: 2rem 0 0 0;
    }
` as typeof StyledSection.Root;

export const StyledHorizontalNav = styled(HorizontalNav.Root)`
    &${HorizontalNav.Container} {
        background-color: ${props => props.theme.white};
        display: flex;
        flex-direction: row;
    }
    &${HorizontalNav.Container} > div {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &${HorizontalNav.MenuList} {
        width: ${props => props.theme.layout.tablet};
        color: ${props => props.theme.neutral.faint};
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
` as typeof HorizontalNav.Root;

export const StyledHorizontalNavMenuItem = styled(HorizontalNavMenuItem.Root)`
    padding: 1rem 0rem;
    border-radius: ${props => props.theme.borderRadius};
    font-size: 1.15rem;
    transition: ${props => props.theme.transition};
    text-align: center;
    text-decoration: none;
    &${HorizontalNavMenuItem.Text} {
        font-family: ${props => props.theme.font.display};
        padding: 0.4rem;
        text-align: center;
        transition: none;
    }
    &&${HorizontalNavMenuItem.Link} {
        color: ${props => props.theme.secondary.light};
        border-top: 1px solid transparent;
        &.active {
            background-color: ${props => props.theme.white};
            box-shadow: inset 0px 5px 36px -13px rgba(105, 104, 104, 0.8);
            border-top-color: ${props => props.theme.primary.main};
            span {
                &:last-child {
                    &::before {
                        color: ${props => props.theme.secondary.light};
                        content: '';
                        font-size: ${props => props.theme.font.sizes.normal};
                        border-left: 2px solid ${props => props.theme.primary.dark};
                        font-family: ${props => props.theme.font.display};
                        position: absolute;
                        top: 6px;
                        left: 0px;
                        height: 1rem;
                    }
                }
            }
        }
        height: 100%;
        width: 100%;

        &:hover,
        &:focus {
            background-color: ${props => props.theme.primary.main};
            color: ${props => props.theme.secondary.main};
            box-shadow: inset 0px 5px 36px -13px rgba(105, 104, 104, 0.8);
            span {
                color: ${props => props.theme.secondary.main};
                &:last-child {
                    &::before {
                        color: ${props => props.theme.secondary.main};
                        content: '';
                        font-size: ${props => props.theme.font.sizes.normal};
                        border-left: 2px solid #000;
                        font-family: ${props => props.theme.font.display};
                        position: absolute;
                        top: 6px;
                        left: 0px;
                        height: 1rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1em;
    }
` as typeof HorizontalNavMenuItem.Root;

export const customLayoutStyles = (props: { theme: ICustomTheme }) =>
    `
    &${ResponsiveLayout.Content} {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding: 4rem 0;
        margin: 1rem;
        @media screen and (max-width: calc(${props.theme.layout.mobile} - 1px)) {
            gap: 1rem;
            padding: 2rem 0;
        }
    }
`;

export const CustomLayout = styled(ResponsiveLayout.Root)`
    background: ${props => props.theme.neutral.extraLight};
    ${customLayoutStyles};
` as typeof ResponsiveLayout.Root;

export const CompletionPageLayout = styled(ResponsiveLayout.Root)`
    background: ${props => props.theme.neutral.extraLight};
    &${ResponsiveLayout.Content} {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        @media screen and (min-width: 37.5rem) {
            padding: 3rem 0 20rem;
        }
    }
` as typeof ResponsiveLayout.Root;

export const NavCustomLayout = styled(ResponsiveLayout.Root)`
    background: ${props => props.theme.white};
    ${customLayoutStyles};
` as typeof ResponsiveLayout.Root;

export const LeadingSeparator = styled(Separator.Root)`
    margin-bottom: 1.5rem;
    width: 2rem;
    height: 2px;
`;

export const autoCompleteStyles = {
    option: (baseStyles: CSSObjectWithLabel, state: OptionProps<any, false, any>) => ({
        ':nth-child(odd)': { background: state.isFocused ? theme.neutral.extraLight : theme.neutral.autocompleteLight, color: theme.secondary.main },
        ':nth-child(even)': { background: state.isFocused ? theme.neutral.extraLight : theme.white, color: theme.secondary.main },
        ':hover': { background: theme.neutral.extraLight },
        padding: '.75rem',
        borderRadius: theme.borderRadius,
        ':last-child': {
            background: theme.secondary.main,
            color: theme.white,
            border: 'none',
            ':hover': { background: theme.primary.main, color: theme.secondary.main },
        },
        borderBottom: `1px solid ${theme.neutral.light}`,
    }),
    // NOTE: it's required to pass baseStyles, but you don't need to use it in the function.
    // state is optional in all of the callbacks. Each item has its own type of props that can be imported from react-select
    // we inherit this syntax from a component library under the hood
    menuList: (baseStyles: CSSObjectWithLabel) => ({
        boxShadow: 'none',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        border: `1px solid  ${theme.neutral.light}`,
    }),
    control: (baseStyles: CSSObjectWithLabel, state: ControlProps<any, false, any>) => ({
        padding: '0.5rem',
        border: `1px solid ${theme.neutral.light}`,
        background: state.isFocused ? theme.white : theme.primary.input,
        color: theme.secondary.dark,
        fontSize: theme.font.sizes.normal,
        lineHeight: '1.6',
        transition: theme.transition,
        borderRadius: theme.borderRadius,
        boxShadow: state.isFocused ? `0px 2px 0px 0px ${theme.primary.main}` : 'none',
        zIndex: state.isFocused ? 1 : 0,
        outline: 'none',
        ':hover': {
            transition: theme.transition,
            backgroundColor: theme.white,
            zIndex: 1,
            outline: 'none',
        },
    }),

    menu: (baseStyles: CSSObjectWithLabel) => ({
        backgroundColor: theme.white,
        boxShadow: theme.boxShadow.main,
        borderRadius: theme.borderRadius,
    }),
};

export const StyledTextInput = styled(TextInput.Root)`
    &${Label} {
        ${commonLabelStyles};
        padding: ${props => props.theme.padding.label};
    }
    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }
    &${StyledInput.Container} {
        ${commonMobileDeviceMediaQuery};
    }

    ${commonMobileDeviceMediaQuery};

    ${smallDeviceIconWidth};
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
` as typeof TextInput.Root;

export const StyledDatePicker = styled(DatePicker.Root)`
    &${Label} {
        ${commonLabelStyles};
        padding: ${props => props.theme.padding.label};
    }

    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }
    ${commonMobileDeviceMediaQuery};

    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    ${smallDeviceIconWidth};
` as typeof DatePicker.Root;

export const StyledRadioInput = styled(RadioInput.Root)`
    &${RadioInput.ItemContainer} {
        gap: 0.5rem;
    }
    &${Label} {
        ${commonLabelStyles};
        padding: 0.5em 0 0.5em 0;
    }
    &${RadioInput.RadioGroupItem} {
        cursor: pointer;
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
` as typeof RadioInput.Root;

export const StyledMonthDate = styled(MonthDate.Root)`
    &${MonthDate.SelectGroup} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0.5rem 0;
    }
    &${Label} {
        margin: 0.5rem 0;
    }
    &${Select.Trigger} {
        ${commonInputFieldStyleRules};
    }

    &${Select.Item} {
        ${commonDropDownStyleRules};
    }
    &${Select.ItemIndicator} {
        background-color: ${props => props.theme.neutral.selectIcon};
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
` as typeof MonthDate.Root;

export const StyledMaskedInput = styled(MaskedInput.Root)`
    &${Label} {
        ${commonLabelStyles};
        padding: ${props => props.theme.padding.label};
    }

    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }

    &${ToggleVisibility.Button} {
        @media screen and (max-width: ${props => props.theme.layout.mobile}) {
            width: 2rem;
        }
    }

    ${commonMobileDeviceMediaQuery};

    ${smallDeviceIconWidth};
` as typeof MaskedInput.Root;

export const StyledMaskedPhone = styled(MaskedPhone.Root)`
    &${Label} {
        ${commonLabelStyles};
        padding: ${props => props.theme.padding.label};
    }
    &${SingleCheckInput.Checkbox} {
        margin-top: 0.5rem;
    }
    &${SingleCheckInput.CheckIcon} {
        color: ${props => props.theme.secondary.dark};
    }
    &${StyledInput.Input} {
        ${commonInputFieldStyleRules};
    }
    &${StyledInput.Container} ~ div {
        @media screen and (max-width: ${props => props.theme.layout.mobile}) {
            flex-flow: column;
        }
    }
    &${SingleCheckInput.ItemContainer} {
        align-items: flex-start;
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    ${commonMobileDeviceMediaQuery};

    ${smallDeviceIconWidth};
` as typeof MaskedPhone.Root;

export const StyledText = styled.span`
    font-size: 0.875em;
    font-style: italic;
`;

export const StyledSelectInput = styled(Select.Root)`
    &${Label} {
        ${commonLabelStyles};
        padding: ${props => props.theme.padding.label};
    }
    &${FormWrapper.InputContainer} {
        width: 100%;
    }
    &${Select.Trigger} {
        ${commonInputFieldStyleRules};
    }

    &${Select.Item} {
        ${commonDropDownStyleRules};
    }

    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    &${Select.Icon} {
        @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
            width: 2rem;
        }
    }
    &${Select.ItemIndicator} {
        background-color: ${props => props.theme.neutral.selectIcon};
    }
` as typeof Select.Root;

export const StyledCheckInputGroup = styled(CheckInputGroup.Root)`
    &${Label} {
        align-items: flex-start;
    }
    &${SingleCheckInput.ItemContainer} {
        margin-bottom: 0.5rem;
    }
    ${smallDeviceIconWidth};
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    &${SingleCheckInput.CheckIcon} {
        color: ${props => props.theme.secondary.dark};
    }
    @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
        &${CheckInputGroup.InputContainer} {
            padding: 1rem 0 0 0;
        }
    }
` as typeof CheckInputGroup.Root;

export const StyledSingleCheckInput = styled(SingleCheckInput.Root)`
    &${Label} {
        align-items: flex-start;
    }
    &${SingleCheckInput.ItemContainer} {
        margin-bottom: 0.5rem;
    }
    &${SharedErrorStyling.Message} {
        color: ${props => props.theme.error.main};
        padding: 0.125rem;
    }
    &${SingleCheckInput.CheckIcon} {
        color: ${props => props.theme.secondary.dark};
    }
` as typeof SingleCheckInput.Root;
