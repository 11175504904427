import { ITheme } from '@papa/components';
export const theme: ICustomTheme = {
    primary: {
        main: '#ffc212', // primary action color -- impacts the outline of inputs on hover / focus
        light: '#ffc212',
        dark: '#d09f23',
        darkest: '#B78C1F',
        darker: '#B88A00',
        action: '#555453',
        input: 'rgba(149,139,137,0.08)',
    },
    success: {
        light: '#B2D5B6',
        main: '#2A7B3B',
        dark: '#1F4426',
    },
    secondary: {
        light: '#696868',
        main: '#000', // should be used as a label color
        dark: '#222',
    },
    accent: {
        light: '#00B0E0',
        main: '#0d7a9a',
        dark: '#052f3c',
    },
    neutral: {
        faint: '#fbfbfb',
        selectIcon: '#edf4f7',
        completionLight: 'rgba(255,255,255,0.6)',
        autocompleteLight: 'rgba(149,139,137,0.1)',
        extraLight: '#dee6ea',
        light: '#958b89', //  this is used for the outline of form components when not in focus / hover
        main: '#6E6E6E',
        dark: '#303030',
        darkest: 'rgba(0, 0, 0, 0.8)', // the overlay for the loading symbol on page load when logged in
    },
    error: {
        light: 'white',
        main: '#dc3618',
        dark: '#dc3618',
        darkest: '#6F3638',
    },
    disabled: {
        light: '#dbdbdb',
        main: '#707070',
        dark: '#303030',
    },
    font: {
        main: "'Open Sans', sans-serif",
        display: "'Playfair Display', Palatino, Georgia, sans-serif",
        note: "'OpenSans', Helvetica, Roboto, Arial, sans-serif",
        subheader: "'Playfair', Palatino, Georgia, serif",
        italic: "'Roboto', sans-serif",
        bold: "'BenchNine', 'OpenSans', Helvetica, Roboto, Arial, sans-serif",
        sizes: {
            xsmall: '0.875em',
            small: '0.9375rem',
            medium: '1.5rem',
            normal: '1rem',
            large: '2.5em',
            larger: '2.25rem',
            thanksMessage: 'calc( 1.25rem + (1.75 - 1.25) * ( (100vw - 20rem) / ( 75 - 20) ))',
            download: '1.15rem',
            header: '1.85rem + (2.25 - 1.85) * ((100vw - 20rem) / (75 - 20))',
            menu: '1rem + (1.15 - 1) * ((100vw - 20rem) / (75 - 20))',
            envelopeIcon: '1.7rem',
            arrowIcon: 'calc(1rem + (1.15 - 1) * ((100vw - 20rem) / (75 - 20)))',
            completionLinkHeader: 'calc(1.85rem + (2.25 - 1.85) * ((100vw - 20rem) / (75 - 20)))',
            completionAddress: '1.125em',
            completionAddressTablet: '1em',
            imageTextCalc: 'calc( 1rem + (1.15 - 1) * ( (100vw - 20rem) / ( 75 - 20) ))',
            imageTextNormal: '1.15rem',
            textOptIn: '0.9em',
            schoolLogoWidth: 'calc(13rem + 2 * ((100vw - 37.5rem) / 26.5))',
            schoolLogoHeight: 'calc(2rem + 1 * ((100vw - 30rem) / 34))',
        },
    },
    layout: {
        tablet: '900px',
        mobile: '675px',
        linkSectionLarge: '767px',
        linkSectionSmall: '600px',
    },
    boxShadow: {
        main: '0 2px 23px 0 rgba(0 0 0 / 0.3)',
        heavy: '0 3px 10px rgb(0 0 0 / 0.4)',
        completion: '0 0 9px rgba(0, 0, 0, 0.2)',
        input: '0px 2px 0px 0px rgb(255, 194, 18)',
    },
    borderRadius: '0',
    white: 'white',
    transition: 'all 0.25s ease-in-out',
    padding: {
        label: '0.5em 0 0.5em 0',
        validationErrorSummary: '0.75rem + 0.75 * ((100vw - 20rem) / 55)) calc(1rem + 1 * ((100vw - 20rem) / 55)',
        styledSection: '1rem + 1 * ((100vw - 20rem) / 55)) calc(1.25rem + 0.75 * ((100vw - 20rem) / 55)',
        responsiveColumns: 'calc(0.75rem + 0.5 * ((100vw - 20rem) / 55)) calc(1.25rem + 0.75 * ((100vw - 20rem) / 55))',
        bottomNavPadding: 'calc(0rem + 0 * ((100vw - 18.75rem) / 56.25)) calc(0rem + 0 * ((100vw - 18.75rem) / 56.25)) calc(2rem + 0.5 * ((100vw - 18.75rem) / 56.25))',
        accountCardContainer: 'calc(1.5rem + 2 * ((100vw - 20rem) / 55)) 0.25rem',
        applicationHeader: 'calc(1rem + 0.5 * ((100vw - 20rem) / 55)) calc(0rem + 0 * ((100vw - 20rem) / 55))',
    },
};
/* 
    If an app has styling choices that do not fit in with the provided theme, you can 
    customize your theme type. In the example below, we added different shades of pink 
    (salmon, regular, rose) as well as a light box shadow. Using a theme interface and 
    theme variable makes it easier to update an app in the case of partner rebranding.
*/
export interface ICustomTheme extends ITheme {
    primary: {
        light: string;
        main: string;
        dark: string;
        darkest: string;
        darker: string;
        action: string;
        input: string;
    };
    error: {
        light: string;
        main: string;
        dark: string;
        darkest: string;
    };
    neutral: {
        faint: string;
        selectIcon: string;
        completionLight: string;
        autocompleteLight: string;
        extraLight: string;
        light: string;
        main: string;
        dark: string;
        darkest: string;
    };
    layout: {
        tablet: string;
        mobile: string;
        linkSectionLarge: string;
        linkSectionSmall: string;
    };
    boxShadow: {
        light?: string; // example of adding custom choices
        main: string;
        heavy: string;
        completion: string;
        input: string;
    };
    font: {
        main: string;
        note: string;
        display: string;
        subheader: string;
        italic: string;
        bold: string;
        sizes: {
            xsmall: string;
            small: string;
            medium: string;
            normal: string;
            large: string;
            larger: string;
            thanksMessage: string;
            download: string;
            header: string;
            menu: string;
            envelopeIcon: string;
            arrowIcon: string;
            completionLinkHeader: string;
            completionAddress: string;
            completionAddressTablet: string;
            imageTextCalc: string;
            imageTextNormal: string;
            textOptIn: string;
            schoolLogoWidth: string;
            schoolLogoHeight: string;
        };
    };
    transition: string;
    padding: {
        label: string;
        validationErrorSummary: string;
        styledSection: string;
        responsiveColumns: string;
        bottomNavPadding: string;
        applicationHeader: string;
        accountCardContainer: string;
    };
}

export enum FontSizesEnum {
    xsmall = 'xsmall',
    small = 'small',
    medium = 'medium',
    normal = 'normal',
    large = 'large',
    larger = 'larger',
    thanksMessage = 'thanksMessage',
    header = 'header',
    menu = 'menu',
    download = 'download',
    envelopeIcon = 'envelopeIcon',
    arrowIcon = 'arrowIcon',
    completionLinkHeader = 'completionLinkHeader',
    completionAddress = 'completionAddress',
    completionAddressTablet = 'completionAddressTablet',
    imageTextCalc = 'imageTextCalc',
    imageTextNormal = 'imageTextNormal',
    textOptIn = 'textOptIn',
    schoolLogoWidth = 'schoolLogoWidth',
    schoolLogoHeight = 'schoolLogoHeight',
}
