import { Conditional } from '@papa/components';
import styled from 'styled-components';
import { StyledLink, StyledLinkErrorMessage } from '../styles/FormElements';
import dropins from '../emails/dropins.json';
import { conditionIsLoggedIn } from './WelcomeBanner';

export const WelcomeTitle = styled.h2`
    font-family: ${props => props.theme.font.display};
    font-size: 2.5rem;
    line-height: 1.2;
    color: ${props => props.theme.white};
    margin: 0;
    padding: 0.5;
    font-weight: 500;
    margin-bottom: 2rem;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: calc(${props => props.theme.font.sizes.header});
    }
    font-weight: 400;
`;

export const PreviewContent = styled.p`
    margin: 1rem 0 1rem 0;
    font-style: normal;
    font-size: 1.0625rem;
    line-height: 1.6;
    font-family: ${props => props.theme.font.main};
    font-weight: 400;
    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1rem;
        line-height: 1.4;
    }
`;

export const AlertMessage = styled.div`
    display: flex;
    background-color: ${props => props.theme.error.main};
    color: ${props => props.theme.white};
    padding: 0.5rem 1.5rem;
    margin-bottom: 2rem;
`;

export const WelcomeLoggedInMessage = () => {
    return (
        <Conditional.ClientSnapshot
            conditionObj={conditionIsLoggedIn}
            renderProps={{
                falsyChild: (
                    <>
                        <AlertMessage>
                            <PreviewContent>
                                We have recently updated our application. If you started an application prior to Month XX, please{' '}
                                <StyledLinkErrorMessage href="/signup">create a new account</StyledLinkErrorMessage>.
                            </PreviewContent>
                        </AlertMessage>
                        <WelcomeTitle>Take a peek at your streamlined {dropins.application_title} now!</WelcomeTitle>
                        <PreviewContent>
                            Great news for you — we've waived your application fee, and we'll send you a two-week admission decision once all of your supplemental
                            materials have been received.
                        </PreviewContent>
                        <PreviewContent>
                            If you have already started your application, you can <StyledLink href="/login">return to it now</StyledLink>.
                        </PreviewContent>
                    </>
                ),
                truthyChild: (
                    <>
                        <WelcomeTitle>Begin your streamlined {dropins.application_title} now!</WelcomeTitle>
                        <PreviewContent>
                            It's time to take the first step toward a bright future. You can complete your application all in one sitting, or you can return and finish
                            applying at any time with login credentials you created.
                        </PreviewContent>
                        <PreviewContent>
                            Remember, there's no application fee or required essay. Plus, you'll be considered for scholarships, and we'll send you an admission decision
                            in two weeks once your application has been submitted.
                        </PreviewContent>
                    </>
                ),
            }}
        />
    );
};
