import { MaterialIcon, ResponsiveForm, ThemeColor, ThemeShade } from '@papa/components';
import { StyledLinkFooter, lightSeparator } from '../../styles/FormElements';
import styled from 'styled-components';
import dropins from '../../emails/dropins.json';
import { JSX } from 'react';
import { FontSizesEnum } from '../../styles/theme';

export const yesOrNoAnswers = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
];

interface IWrapperProps {
    children: JSX.Element | JSX.Element[];
}

export const CustomFormWrapper = (props: IWrapperProps) => {
    return (
        <>
            <ResponsiveForm.Root>{props.children}</ResponsiveForm.Root>
            {lightSeparator}
        </>
    );
};

export const ExtraPaddingFormWrapper = styled(ResponsiveForm.Root)`
    &${ResponsiveForm.Container} {
        padding-bottom: 2rem;
    }
` as typeof ResponsiveForm.Root;

export const Subtext = styled.p<{
    italic?: boolean;
    size?: keyof typeof FontSizesEnum;
    padding?: string;
    lineHeight?: string;
    fontWeight?: string;
    color: keyof typeof ThemeColor;
    shade: keyof typeof ThemeShade;
}>`
    ${props => (props.italic ? 'font-style: italic;' : '')};
    font-family: ${props => props.theme.font.main};
    font-size: ${props => props.theme.font.sizes[props.size || 'small']};
    color: ${props => props.theme[props.color][props.shade] || props.theme.secondary.main};
    margin: 0;
    padding: ${props => props.padding || '0'};
    line-height: ${props => props.lineHeight || '1.4'};
    font-weight: ${props => props.fontWeight || 'normal'};
`;

export const SubtextExtraPadding = styled(Subtext)`
    padding-bottom: 1rem;
`;

export const UppercaseSubheader = styled.h3`
    color: ${props => props.theme.secondary.main};
    font-size: 1.35rem;
    margin: 0.5rem 0 1.25rem;
    font-family: ${props => props.theme.font.subheader};
    font-weight: bold;
    line-height: 1.2;
`;

export const Subheader = styled.h3`
    color: ${props => props.theme.secondary.dark};
    font-size: 1.35rem;
    margin: 1.25rem 0 0.5rem 0;
    line-height: 1.2;
    font-weight: 700;
    font-family: ${props => props.theme.font.display};
`;

export const UppercaseSubtext = styled.span`
    font-size: 0.7em;
    letter-spacing: 0.1rem;
    font-weight: 700;
    font-family: ${props => props.theme.font.display};
    text-transform: uppercase;
`;

export const NoteText = styled.p`
    color: ${props => props.theme.secondary.main};
    margin: 0;
    font-family: ${props => props.theme.font.note};
    font-style: italic;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.6;
`;

export const NoteIcon = styled(MaterialIcon.Root)`
    top: 0.125rem;
    font-size: 1.35rem;
    color: ${props => props.theme.primary.dark};
`;

const NoteDiv = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-left: -0.2rem;
`;

export const Note = (props: { text: string }) => {
    return (
        <>
            <NoteDiv>
                <NoteIcon iconName="push_pin" />
                <NoteText>{props.text}</NoteText>
            </NoteDiv>
        </>
    );
};

interface IPartnerMailLinkProps {
    dropinsKey: keyof typeof dropins;
}
export const PartnerMailToLink = (props: IPartnerMailLinkProps) => (
    <StyledLinkFooter target="_blank" href={`mailto:${dropins[props.dropinsKey]}`}>
        {dropins[props.dropinsKey]}
    </StyledLinkFooter>
);

export const SurroundingForwardSlashes = styled.div<{ accountPage?: boolean; completionPage?: boolean }>`
    position: relative;
    ${props =>
        props.accountPage ?? true
            ? `display:flex;
        margin-bottom: 1rem;
        padding: 0.25rem 0;
        background: ${props.theme.neutral.extraLight};
        box-shadow: inset 0 17px 23px -17px rgba(0,0,0,0.3);`
            : 'background: none;'}
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: ${props => (props.completionPage ? '3.5rem' : '1.5rem')};
        left: 0;
        top: ${props => (props.completionPage ? '-1rem' : '0')};
        background: url(img-whitediagonal-top.png) 50% repeat-x;

        @media (min-width: 75rem) {
            height: ${props => (props.accountPage ?? true ? '3.5rem' : '4.5rem')};
        }

        @media (min-width: 20rem) {
            height: calc(1.5rem + 2 * ((100vw - 20rem) / 55));
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: ${props => (props.completionPage ? '3.5rem' : '1.5rem')};
        left: 0;
        bottom: -1rem;
        background: url(img-whitediagonal-btm.png) 50% repeat-x;

        @media (min-width: 75rem) {
            height: 3.5rem;
        }

        @media (min-width: 20rem) {
            height: calc(1.5rem + 2 * ((100vw - 20rem) / 55));
        }
    }
`;
