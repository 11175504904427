import { string, boolean, date } from 'yup';
import { UserGeneratedAnswersEnum, UserGeneratedAnswersKeys } from './types';
import { generateSchema, SchemaLogicType } from '@papa/components';
import dropins from '../emails/dropins.json';

const currentDate = new Date();
export const currentYear = currentDate.getFullYear();
const earliestSupportedYear = currentYear - 81;
const latestSupportedYear = currentYear - 13;
const supportedMonth = currentDate.getMonth();
const supportedDate = currentDate.getDate();

const isValidString = (value: string) => {
    return value && value.length > 0;
};

const personalInformationSchema = {
    first_name: string().required('First name'),
    middle_name: string().optional(),
    last_name: string().required('Last name'),
    preferred_name: string().optional(),
    prev_lname: string().optional(),
    gender: string().required('Gender'),
    birth_date: date()
        .typeError('Date of Birth')
        .required('Date of Birth')
        .min(
            new Date(earliestSupportedYear, supportedMonth, supportedDate),
            `The minimum supported year is ${earliestSupportedYear}. Please contact help support for assistance.`
        )
        .max(new Date(latestSupportedYear, supportedMonth, supportedDate), `You must be ${currentYear - latestSupportedYear} years or older to apply.`),
};

const contactInfoSchema = {
    phone_home: string()
        .matches(/^$|\(\d\d\d\)-\d\d\d-\d\d\d\d/, 'Please provide a valid US phone number for home phone.')
        .optional(),
    phone_mobile: string()
        .matches(/^$|\(\d\d\d\)-\d\d\d-\d\d\d\d/, 'Please provide a valid US phone number for cell phone.')
        .when('phone_home', {
            is: (value: string) => !!value,
            then: schema =>
                schema.when('optin_sms_ind', {
                    is: true,
                    then: schema => schema.required('A cell phone number is required to opt in to text message updates.'),
                    otherwise: schema => schema.optional(),
                }),
            otherwise: schema => schema.required('Please enter either a home phone or cell phone number.'),
        }),
    optin_sms_ind: boolean().optional(),
    address_line_1: string().required('Address'),
    address_line_2: string().optional(),
    city: string().required('City'),
    state: string().when('country', {
        is: (value: string) => value && ['USA', 'CAN'].includes(value.toUpperCase()),
        then: schema => schema.required('State'),
        otherwise: schema => schema.optional(),
    }),
    zip: string().when('country', {
        is: (value: string) => value && ['USA'].includes(value.toUpperCase()),
        then: schema => schema.required('Zip/Postal Code is required').min(5, 'Zip/Postal Code must be at least 5 characters.'),
        otherwise: schema => schema.optional(),
    }),
    country: string().required('Country'),
    mailing_addr_same_as_perm_addr: string().optional(),
    mailing_address_line_1: string().optional(),
    mailing_address_line_2: string().optional(),
    mailing_city: string().optional(),
    mailing_state: string().optional(),
    mailing_zip: string().optional(),
    mailing_country: string().optional(),
    mailing_addr_effective_date: date()
        .transform(value => (value === '' || 'Invalid Date' ? null : value))
        .nullable(),
};

const citizenshipSchema = {
    citizenship: string().optional(),
    citizenship_type: string().optional(),
    birth_country: string().optional(),
    birth_state: string().optional(),
    citizen_country: string().optional(),
    alien_registration_number: string().optional(),
    citizenship_visa_status: string().optional(),
};

const ethnicitySchema = {
    ethnicity: string().optional(),
    race_american_indian: boolean().optional(),
    race_asian: boolean().optional(),
    race_black: boolean().optional(),
    race_native_hawaiian: boolean().optional(),
    race_white: boolean().optional(),
};

const militarySchema = {
    military_status: string().optional(),
    military_branch: string().optional(),
    military_status_specified: string().optional(),
    military_dependent: string().optional(),
    military_benefits: string().optional(),
};

const yourPlansSchema = {
    applied_major: string().required('Please select a major.'),
    entry_term: string().required('Please select when you plan to enroll.'),
    financial_aid: string().optional(),
};

const educationalHxSchema = {
    first_time_student: string().required('Please indicate if you describe yourself as a first-time student.'),
    prev_attend: string().required(`Please indicate if you have previously attended ${dropins.partner_name}.`),
    prev_attend_from_month: string().optional(),
    prev_attend_to_month: string().optional(),
    prev_attend_from_year: string().optional(),
    prev_attend_to_year: string().optional(),
    hs_name: string().required('Search for Your School'),
    hs_ceeb: string().optional(),
    hs_city: string().optional(),
    hs_state: string().optional(),
    hs_zip: string().optional(),
    hs_address_line_1: string().optional(),
    hs_grad_month: string().optional(),
    hs_grad_year: string().optional(),
    college_degree: string().optional(),
    college_gpa: string().optional(),
    college_credits: string().optional(),
    college_attend_from_month: string().when('college_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required for start date.'),
        otherwise: schema => schema.optional(),
    }),
    college_attend_to_month: string().when('college_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required for end date.'),
        otherwise: schema => schema.optional(),
    }),
    college_attend_from_year: string().when('college_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required for start date.'),
        otherwise: schema => schema.optional(),
    }),
    college_attend_to_year: string().when('college_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required for end date.'),
        otherwise: schema => schema.optional(),
    }),
    college_2_degree: string().optional(),
    college_2_gpa: string().optional(),
    college_2_credits: string().optional(),
    college_2_attend_from_month: string().optional(),
    college_2_attend_to_month: string().optional(),
    college_2_attend_from_year: string().optional(),
    college_2_attend_to_year: string().optional(),
    college_3_degree: string().optional(),
    college_3_gpa: string().optional(),
    college_3_credits: string().optional(),
    college_3_attend_from_month: string().optional(),
    college_3_attend_to_month: string().optional(),
    college_3_attend_from_year: string().optional(),
    college_3_attend_to_year: string().optional(),
    college_4_degree: string().optional(),
    college_4_gpa: string().optional(),
    college_4_credits: string().optional(),
    college_4_attend_from_month: string().optional(),
    college_4_attend_to_month: string().optional(),
    college_4_attend_from_year: string().optional(),
    college_4_attend_to_year: string().optional(),
    college_5_degree: string().optional(),
    college_5_gpa: string().optional(),
    college_5_credits: string().optional(),
    college_5_attend_from_month: string().optional(),
    college_5_attend_to_month: string().optional(),
    college_5_attend_from_year: string().optional(),
    college_5_attend_to_year: string().optional(),
    college_name: string().optional(),
    college_2_name: string().optional(),
    college_3_name: string().optional(),
    college_4_name: string().optional(),
    college_5_name: string().optional(),
    college_address_line_1: string().optional(),
    college_2_address_line_1: string().optional(),
    college_3_address_line_1: string().optional(),
    college_4_address_line_1: string().optional(),
    college_5_address_line_1: string().optional(),
    college_city: string().optional(),
    college_2_city: string().optional(),
    college_3_city: string().optional(),
    college_4_city: string().optional(),
    college_5_city: string().optional(),
    college_state: string().optional(),
    college_2_state: string().optional(),
    college_3_state: string().optional(),
    college_4_state: string().optional(),
    college_5_state: string().optional(),
    college_zip: string().optional(),
    college_2_zip: string().optional(),
    college_3_zip: string().optional(),
    college_4_zip: string().optional(),
    college_5_zip: string().optional(),
    college_ceeb: string().optional(),
    college_2_ceeb: string().optional(),
    college_3_ceeb: string().optional(),
    college_4_ceeb: string().optional(),
    college_5_ceeb: string().optional(),
};

const testScoresSchema = {
    toefl_taken: string().optional(),
    toefl_taken_month: string().optional(),
    toefl_taken_year: string().optional(),
    toefl_score: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizenship_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, { message: 'Please provide a valid TOEFL Score.', excludeEmptyString: true }),
                otherwise: schema => schema.optional(),
            }),
    }),
    toefl_plan_month: string().optional(),
    toefl_plan_year: string().optional(),
};

const employmentSchema = {
    employ_current: string().optional(),
    employer_name: string().optional(),
};

const finalStepsSchema = {
    conviction: string().required('Have you been convicted of a felony?'),
    conviction_comment: string().when('conviction', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.required('Have you been convicted of a felony? Please explain.'),
        otherwise: schema => schema.optional(),
    }),
    suspended: string().required('Have you been suspended from any school?'),
    suspended_comment: string().when('suspended', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.required('Have you been suspended from any school? Please explain.'),
        otherwise: schema => schema.optional(),
    }),
    agree_counselor_request_waiver: string().required('I waive my right to review or access letters and statements of recommendation written on my behalf.'),
    legal_agree: boolean()
        .required('I certify that all information submitted in the admission process is my own work, factually true, and honestly presented...')
        .oneOf([true], 'I certify that all information submitted in the admission process is my own work, factually true, and honestly presented...'),
};

export const schema = generateSchema<SchemaLogicType<UserGeneratedAnswersKeys>, typeof UserGeneratedAnswersEnum>(
    {
        ...contactInfoSchema,
        ...personalInformationSchema,
        ...citizenshipSchema,
        ...ethnicitySchema,
        ...militarySchema,
        ...yourPlansSchema,
        ...educationalHxSchema,
        ...testScoresSchema,
        ...employmentSchema,
        ...finalStepsSchema,
    },
    UserGeneratedAnswersEnum
);
